@tailwind base;
@tailwind components;
@tailwind utilities;

.title {
    @apply text-5xl font-semibold;
}

.title-medium {
    @apply text-xl font-semibold;
}

.title-button {
    @apply text-base font-semibold;
}

.recipe-btn {
    box-shadow: 1px 3px 3px rgb(0, 0, 0, .2), -1px 3px 3px rgb(0, 0, 0, .2);
}

color {
    background-color: teal;
}

/*FOR SPINNER*/
.spinner {
    width: 40px;
    height: 40px;
    position: relative;
    margin: 0px auto;
}

.double-bounce1,
.double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;

    -webkit-animation: sk-bounce 2s infinite ease-in-out;
    animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {

    0%,
    100% {
        -webkit-transform: scale(0);
    }

    50% {
        -webkit-transform: scale(1);
    }
}

@keyframes sk-bounce {

    0%,
    100% {
        transform: scale(0);
        -webkit-transform: scale(0);
    }

    50% {
        transform: scale(1);
        -webkit-transform: scale(1);
    }
}

.custom-scrollbar::-webkit-scrollbar {
    width: 5px;
}

.custom-scrollbar-main::-webkit-scrollbar-thumb {
    background-color: #58C16D;
    border-radius: 10px;
}

.custom-scrollbar-light::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 10px;
}

@media (max-width: 600px) {
    .notifications-modal {
        width: 100vw;
        position: fixed;
        left: 0;
        top: 0;
        min-height: 100vh;
        border-radius: 0;
    }

    .close-notifications-button {
        display: block;
    }
}